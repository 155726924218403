import { useState } from "react"

import { Tooltip } from "@material-ui/core"

import SearchFilter from "src/components/Filter/SearchFilter"
import { useUrlPager } from "src/components/Pager/useUrlPager"
import { ParadisePager } from "src/components/Paradise/ParadisePager"
import { ParadiseTable } from "src/components/Paradise/ParadiseTable"
import {
  DescriptionWrapper,
  Ellipsis,
  FilterWrapper,
  IdWrapper,
  ParadiseLayout,
  PillsWrapper,
  TopWrapper,
} from "src/components/Paradise/sharedStyles"
import {
  TableColumn,
  useTableColumns,
} from "src/components/Paradise/useTableColumns/useTableColumns"
import { useFetchParadiseHomes } from "src/data/paradise/paradiseHomes/queries/paradiseHomeQueries"
import { IParadiseHome } from "src/data/paradise/paradiseHomes/types/paradiseHomeQuerytypes"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { colors } from "src/ui/colors"
import { DropdownMultiSelect } from "src/ui/DropdownSelect/DropdownMultiSelect"
import InfoIcon from "src/ui/icons/info.svg"
import { Titlebar } from "src/ui/Layout/Titlebar"
import { InternalLink } from "src/ui/Link/InternalLink"
import { MBadge } from "src/ui/MBadge/MBadge"
import { MSkeleton } from "src/ui/MSkeleton/MSkeleton"
import { MText } from "src/ui/MText"

const LIMIT = 50

const tableColumns: TableColumn<IParadiseHome>[] = [
  {
    value: "home_id",
    label: "Home id",
    disabled: true,
    columnWidth: "min-content",
    disableClickPropagation: true,
    render: (home) => (
      <div>
        <IdWrapper>
          <InternalLink to={Routes.ParadiseHome.location(home.id)}>
            {home.id}
          </InternalLink>
          {home.deleted && (
            <Tooltip title="Deleted">
              <InfoIcon width={12} fill={colors.systemEmergencyForeground} />
            </Tooltip>
          )}
        </IdWrapper>
      </div>
    ),
  },
  {
    value: "name",
    label: "Name",
    disabled: true,
    columnWidth: 300,
    render: (home) => <Ellipsis>{home.name}</Ellipsis>,
  },
  {
    value: "created_at",
    label: "Created at",
    columnWidth: "min-content",
    render: (home) => <div>{home.created_at}</div>,
  },
  {
    value: "created_by",
    label: "Created by",
    columnWidth: "min-content",
    disableClickPropagation: true,
    render: (home) => (
      <div>
        <InternalLink
          to={Routes.ParadiseUser.location(home.created_by).pathname}
        >
          {home.created_by}
        </InternalLink>
      </div>
    ),
  },
  {
    value: "deleted_at",
    label: "Deleted at",
    columnWidth: "min-content",
    render: (home) => <div>{home.deleted_at ?? "-"}</div>,
  },
  {
    value: "owner_id",
    label: "Owner id",
    columnWidth: "min-content",
    disableClickPropagation: true,
    render: (home) => (
      <div>
        <InternalLink to={Routes.ParadiseUser.location(home.owner.id).pathname}>
          {home.owner.id}
        </InternalLink>
      </div>
    ),
  },
  {
    value: "tags",
    label: "Tags",
    columnWidth: "min-content",
    render: (home) => (
      <PillsWrapper $noWrap>
        {home.tags.map((tag, i) => (
          <MBadge key={i} size="small" color="neutral">
            {tag}
          </MBadge>
        ))}
      </PillsWrapper>
    ),
  },
  {
    value: "timezone",
    label: "Timezone",
    columnWidth: "auto",
    render: (home) => <div>{home.timezone}</div>,
  },
]

export function ParadiseHomes() {
  const { navigate } = useRouter()

  const { limit, offset, setOffset } = useUrlPager({ initialLimit: LIMIT })

  const [filter, setFilter] = useState({
    home_id: "",
    name: "",
    tag: "",
  })

  // If the user has filtered we want to show deleted homes aswell, otherwise just active homes is fetched
  const hasFiltered = Object.values(filter).some((f) => !!f)

  const fetchHomes = useFetchParadiseHomes({
    filter: {
      limit: limit,
      offset: offset,
      home_id: filter.home_id || undefined,
      name: filter.name || undefined,
      tag: filter.tag || undefined,
      sort: "name",
      sort_by: "asc",
      deleted: hasFiltered ? undefined : false,
    },
    options: {
      keepPreviousData: true,
    },
  })
  const homes = fetchHomes.data?.homes

  const {
    headerElements,
    interactiveColumns,
    interactiveVisibleColumns,
    rows,
    setColumnVisibility,
    templateColumns,
  } = useTableColumns<IParadiseHome>({
    columns: tableColumns,
    data: homes,
    options: { localStorageKey: "minut.paradise.homes.table" },
  })

  function handleFilterChange(filterKey: keyof typeof filter, value: string) {
    setOffset(0)
    setFilter((prev) => ({ ...prev, [filterKey]: value }))
  }

  return (
    <ParadiseLayout>
      <Titlebar
        title="Homes"
        description={
          <DescriptionWrapper>
            <MText variant="body">Total:</MText>
            <MText variant="body">
              {!fetchHomes.isLoading ? (
                fetchHomes.data?.total_count
              ) : (
                <MSkeleton width="8ch" />
              )}
            </MText>
          </DescriptionWrapper>
        }
      />
      <TopWrapper>
        <FilterWrapper>
          <SearchFilter
            onChange={(v) => handleFilterChange("home_id", v)}
            label="Search by id"
            fullWidth
          />
          <SearchFilter
            onChange={(v) => handleFilterChange("name", v)}
            label="Search by name"
            fullWidth
          />
          <SearchFilter
            onChange={(v) => handleFilterChange("tag", v)}
            label="Search by tag"
            fullWidth
          />
        </FilterWrapper>
        <DropdownMultiSelect
          label="Columns"
          selectedValues={interactiveVisibleColumns.map((c) => c.value)}
          options={interactiveColumns}
          onChange={({ checked, option }) => {
            setColumnVisibility(option.value, !checked)
          }}
        />
      </TopWrapper>

      <ParadiseTable
        header={headerElements}
        rows={rows ?? []}
        templateColumns={templateColumns}
        onRowClick={(index) => {
          if (homes) {
            navigate(Routes.ParadiseHome.location(homes[index].id))
          }
        }}
        error={{
          hasError: fetchHomes.isError,
          title: fetchHomes.error?.message,
        }}
      />

      {fetchHomes.data && (
        <ParadisePager
          limit={limit}
          offset={offset}
          setOffset={setOffset}
          totalCount={fetchHomes.data.total_count}
        />
      )}
    </ParadiseLayout>
  )
}
